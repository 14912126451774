import { showElement, hideElement } from '/src/common/js';

const bodyRef = document.querySelector('body');
const locationMenuRef = document.querySelector('[data-location-menu]');
const locationMenuBtnRef = document.querySelector('[data-location-menu-btn]');

locationMenuBtnRef?.addEventListener('click', toggleLocationMenu);
locationMenuRef?.addEventListener('click', onLocationMenuClick);

function toggleLocationMenu() {
  const expanded = locationMenuBtnRef.getAttribute('aria-expanded') === 'true' || false;

  if (expanded) hideElement(locationMenuRef);
  else showElement(locationMenuRef);

  bodyRef.classList.toggle('locationMenuIsOpen');
  locationMenuBtnRef.setAttribute('aria-expanded', !expanded);
}
function onLocationMenuClick(e) {
  if (!e.target.hasAttribute('data-location-btn')) return;
  // Визначаємо поточну та нову локації
  const currentLocation = locationMenuBtnRef?.parentElement?.dataset.location;
  const newLocation = e.target.dataset.locationBtn;
  // якщо кліп на поточну локацію, то закриваємо меню
  if (currentLocation === newLocation) return hideLocationMenu();
  // Отримуємо поточний шлях без домену
  const currentPath = window.location.pathname;
  let newUrl = currentPath;
  // Визначаємо мову з currentPath: якщо починається з /ru/, мова російська, інакше - українська
  const isRussian = newUrl.startsWith('/ru/');
  // Видаляємо мовний префікс, якщо він є
  if (isRussian) newUrl = newUrl.replace(/^\/ru/, ''); // Видаляємо /ru з початку шляху
  // Перевіряємо, чи newUrl не дорівнює "/", "/offices", "/team"
  if (
    newUrl !== '/' &&
    newUrl !== '/offices' &&
    newUrl !== '/kyiv/offices' &&
    newUrl !== '/team' &&
    newUrl !== '/kyiv/team'
  ) {
    newUrl = isRussian ? '/ru/' : '/'; // Встановлюємо newUrl на "/" або "/ru/"
    // Оновлюємо URL
    return (window.location.href = newUrl);
  }
  // Видаляємо попередній префікс місця (dnipro або kyiv) з початку шляху
  newUrl = newUrl.replace(/^\/(dnipro|kyiv)/, '');
  // Додаємо новий префікс місця
  if (newLocation === 'kyiv') newUrl = `/kyiv${newUrl}`; // для dnipro префікс не додаємо, оскільки це "за замовчуванням"
  // Додаємо назад мовний префікс, якщо мова російська
  if (isRussian) newUrl = `/ru${newUrl}`;
  // Якщо шлях вийде порожнім (наприклад, лише / або /ru/), додаємо кореневий шлях /
  if (newUrl === '' || newUrl === '/ru') newUrl = isRussian ? '/ru/' : '/';
  // Оновлюємо URL
  window.location.href = newUrl;
}
function hideLocationMenu() {
  hideElement(locationMenuRef);
  bodyRef.classList.remove('locationMenuIsOpen');
  locationMenuBtnRef.setAttribute('aria-expanded', false);
}
