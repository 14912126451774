import { showElement, hideElement } from '/src/common/js';

const bodyRef = document.querySelector('body');
const burgerMenuRef = document.querySelector('[data-burger-menu]');
const burgerMenuBtnRef = document.querySelector('[data-burger-menu-btn]');

updateSize();

window.addEventListener('resize', updateSize);
burgerMenuBtnRef?.addEventListener('click', toggleBurgerMenu);

function toggleBurgerMenu() {
  const expanded = burgerMenuBtnRef?.getAttribute('aria-expanded') === 'true' || false;

  if (expanded) hideElement(burgerMenuRef);
  else showElement(burgerMenuRef);

  bodyRef?.classList.toggle('burgerMenuIsOpen');
  burgerMenuBtnRef?.setAttribute('aria-expanded', !expanded);
}

function hideBurgerMenu() {
  hideElement(burgerMenuRef);
  bodyRef?.classList.remove('burgerMenuIsOpen');
  burgerMenuBtnRef?.setAttribute('aria-expanded', false);
}
function updateSize() {
  const innerWidth = window.innerWidth;

  if (innerWidth <= 1280) hideBurgerMenu();
  // else showBurgerMenu();
}
