const { showElement, hideElement } = require('/src/common/js');

const bookingForm = document.forms.bookingForm;
const nameInput = bookingForm?.elements.name;
const phoneInput = bookingForm?.elements.phone;
const nameError = document?.getElementById('nameError');
const phoneError = document?.getElementById('phoneError');

bookingForm?.addEventListener('submit', onFormSubmit);

nameInput?.addEventListener('input', function (e) {
  const nameValue = e.target.value;
  nameError.textContent = validateName(nameValue);
});
phoneInput?.addEventListener('input', function (e) {
  const phoneValue = e.target.value;
  phoneError.textContent = validatePhone(phoneValue);
});

nameInput?.addEventListener('keypress', handleNameKeyPress);
phoneInput?.addEventListener('keypress', handlePhoneKeyPress);

function onFormSubmit(e) {
  e.preventDefault();

  const nameValidationError = validateName(nameInput?.value);
  const phoneValidationError = validatePhone(phoneInput?.value);

  if (!nameValidationError && !phoneValidationError) {
    sendMail(e.target);
    e.target.reset();
  } else {
    // Відображаємо помилки
    nameError.textContent = nameValidationError;
    phoneError.textContent = phoneValidationError;
  }
}

async function sendMail(formRef) {
  const formData = new FormData(formRef);
  const pageTitle = document.title; // Отримуємо назву сторінки
  const pageUrl = window.location.href;  // Отримуємо Url сторінки
  formData.append('title', pageTitle); // Додаємо назву сторінки
  formData.append('url', pageUrl); // Додаємо Url сторінки

  let sendURL = '/sendmail.php';
  const msgSuccess = formRef?.querySelector('[data-response=success]');
  const msgError = formRef?.querySelector('[data-response=error]');

  // showLoader();
  try {
    let response = await fetch(sendURL, {
      method: 'POST',
      body: formData,
    });
    if (response.ok) {
      showElement(msgSuccess);
    } else showElement(msgError);
  } catch (exceptionVar) {
    showElement(msgError);
  } finally {
    // hideLoader();
    const hideMsg = setTimeout(() => {
      // hideElement(formRef);
      hideElement(msgError);
      hideElement(msgSuccess);
      clearTimeout(hideMsg);
    }, 5000);
  }
}

function validateName(name) {
  // Визначаємо мову з currentPath: якщо починається з /ru/, мова російська, інакше - українська
  const isRussian = window.location.pathname.startsWith('/ru/');
  const erroText1 = isRussian
    ? 'Имя не может быть пустым'
    : "Ім'я не може бути порожнім.";
  const erroText2 = isRussian
    ? 'Имя может содержать только буквы.'
    : "Ім'я може містити тільки літери.";

  if (!name) return erroText1;
  const regex = /^[a-zA-Zа-яА-ЯёЁіІїЇєЄґҐ']+$/u;
  return regex.test(name) ? '' : erroText2;
}
function validatePhone(phone) {
  // Визначаємо мову з currentPath: якщо починається з /ru/, мова російська, інакше - українська
  const isRussian = window.location.pathname.startsWith('/ru/');
  const erroText1 = isRussian
    ? 'Телефон не может быть пустым.'
    : 'Телефон не може бути порожнім.';
  const erroText2 = isRussian
    ? 'Телефон может содержать только цифры и +'
    : 'Телефон може містити тільки цифри та +';

  if (!phone) return erroText1;
  const regex = /[0-9+]/;
  return regex.test(phone) ? '' : erroText2;
}

function handleNameKeyPress(e) {
  const regex = /^[a-zA-Zа-яА-ЯёЁіІїЇєЄґҐ']$/u;
  const char = String.fromCharCode(e.charCode);
  if (!regex.test(char)) {
    e.preventDefault();
  }
}
function handlePhoneKeyPress(e) {
  const regex = /[0-9+]/;
  const char = String.fromCharCode(e.charCode);
  if (!regex.test(char)) {
    e.preventDefault();
  }
}
