import { showElement, hideElement } from '/src/common/js';

const body = document.querySelector('body');
const loader = document.querySelector('.loader');

export function showLoader() {
  body?.classList.add('isLoading');
  showElement(loader);
}

export function hideLoader() {
  body?.classList.remove('isLoading');
  hideElement(loader);
}
