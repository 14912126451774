const { showElement, hideElement } = require('/src/common/js');

const headerLangRef = document.querySelector('header [data-lang]');
const burgerMenuLangRef = document.querySelector('.burger-menu [data-lang]');

headerLangRef?.addEventListener('click', onLangClick);
burgerMenuLangRef?.addEventListener('click', onLangClick);

function onLangClick(e) {
  if (!e.target.hasAttribute('data-lang-btn')) return;

  const currentLangRef = e.currentTarget;
  const allLangBtnsRef = currentLangRef?.querySelectorAll('[data-lang-btn]');

  const isActive = currentLangRef?.classList.contains('active');
  if (!isActive) return currentLangRef?.classList.add('active');

  const currentLang = currentLangRef.dataset.lang;
  const newLang = e.target.dataset.langBtn || 'ua';

  if (currentLang === newLang) return currentLangRef?.classList.remove('active');

  currentLangRef?.classList.remove('active');

  allLangBtnsRef.forEach(i => {
    i.classList.contains('isHidden') ? showElement(i) : hideElement(i);
  });
  const currentPath = window.location.pathname; // Отримуємо поточний шлях без домену
  let newUrl = '';

  // Логіка для зміни URL залежно від мови
  if (newLang === 'ua') {
    // Якщо мова українська, видаляємо будь-який мовний префікс
    newUrl = currentPath.replace(/^\/(en|ru|de)/, ''); // видаляє /en, /fr, /de тощо з початку шляху
  } else {
    // Якщо мова не українська, додаємо мовний префікс
    newUrl = `/${newLang}${currentPath}`;
  }
  // Оновлюємо URL
  window.location.href = newUrl;
}
